import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modificaOrdine } from '../../../actions/ordine';

const ModificaOrdineConfermato = ({ ordine, modificaOrdine, setOpenModificaOrdine }) => {

    const [datiCliente, setDatiCliente] = useState({
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: false,
        ztl: false
    });

    useEffect(() => {
        setDatiCliente({
            pIvaCodiceFiscale: !ordine ? '' : ordine.cliente.pIvaCodiceFiscale,
            nome: !ordine ? '' : ordine.cliente.nome,
            cognome: !ordine ? '' : ordine.cliente.cognome,
            telefono_1: !ordine ? '' : ordine.cliente.telefono_1,
            telefono_2: !ordine ? '' : ordine.cliente.telefono_2,
            telefono_3: !ordine ? '' : ordine.cliente.telefono_3,
            telefono_4: !ordine ? '' : ordine.cliente.telefono_4,
            email_1: !ordine ? '' : ordine.cliente.email_1,
            email_2: !ordine ? '' : ordine.cliente.email_2,
            indirizzo: !ordine ? '' : ordine.cliente.indirizzo,
            cap: !ordine ? '' : ordine.cliente.cap,
            citta: !ordine ? '' : ordine.cliente.citta,
            piano: !ordine ? '' : ordine.cliente.piano,
            scala: !ordine ? '' : ordine.cliente.scala,
            ascensore: !ordine ? '' : ordine.cliente.ascensore,
            ztl: !ordine ? '' : ordine.cliente.ztl
        });
    }, [ordine]);

    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl } = datiCliente;

    const [datiOrdine, setDatiOrdine] = useState({
        _id: null,
        fattura: false,
        misure: 'venditore',
        totale: null,
        acconto: null,
        accontiPersonalizzati: [
            {
                nomeAcconto: '',
                cifraAcconto: null
            }, {
                nomeAcconto: '',
                cifraAcconto: null
            }
        ],
        saldo: null,
        dataPrevista: new Date(),
        descrizioneGenerale: '',
        note: '',
        notePagamento: '',
        notePreventivo: '',
        tipologia: '',
        costoProgetto: {
            prezzo: 0,
            conteggiato: false
        },
        costoMisure: {
            prezzo: 0,
            conteggiato: false
        },
        costoMagazzino: {
            prezzo: 0,
            conteggiato: false
        },
        costoSmontaggio: {
            prezzo: 0,
            conteggiato: false
        },
        costoElettricista: {
            prezzo: 0,
            conteggiato: false
        },
        costoIdraulico: {
            prezzo: 0,
            conteggiato: false
        },
        costoElevatore: {
            prezzo: 0,
            conteggiato: false
        }
    });

    useEffect(() => {
        setDatiOrdine({
            _id: !ordine ? '' : ordine._id,
            fattura: !ordine ? '' : ordine.fattura,
            misure: !ordine ? '' : ordine.misure,
            totale: !ordine ? '' : ordine.totale,
            acconto: !ordine ? '' : ordine.acconto,
            accontiPersonalizzati: !ordine || !ordine.accontiPersonalizzati || ordine.accontiPersonalizzati.length <= 0 ? [
                {
                    nomeAcconto: '',
                    cifraAcconto: null
                }, {
                    nomeAcconto: '',
                    cifraAcconto: null
                }
            ] : ordine.accontiPersonalizzati.length === 1 ? [ordine.accontiPersonalizzati[0], {
                nomeAcconto: '',
                cifraAcconto: null
            }] : ordine.accontiPersonalizzati,
            saldo: !ordine ? '' : ordine.saldo,
            dataPrevista: !ordine ? '' : new Date(ordine.dataPrevista),
            indirizzoPreventivo: !ordine ? '' : ordine.indirizzoPreventivo,
            capPreventivo: !ordine ? '' : ordine.capPreventivo,
            cittaPreventivo: !ordine ? '' : ordine.cittaPreventivo,
            pianoPreventivo: !ordine ? '' : ordine.pianoPreventivo,
            scalaPreventivo: !ordine ? '' : ordine.scalaPreventivo,
            ascensorePreventivo: !ordine ? '' : ordine.ascensorePreventivo,
            ztlPreventivo: !ordine ? '' : ordine.ztlPreventivo,
            descrizioneGenerale: !ordine ? '' : ordine.descrizioneGenerale,
            note: !ordine ? '' : ordine.note,
            notePagamento: !ordine ? '' : ordine.notePagamento,
            notePreventivo: !ordine ? '' : ordine.notePreventivo,
            tipologia: !ordine ? '' : ordine.tipologia,
            costoProgetto: !ordine || !ordine.costoProgetto ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoProgetto,
            costoMisure: !ordine || !ordine.costoMisure ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMisure,
            costoMagazzino: !ordine || !ordine.costoMagazzino ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoMagazzino,
            costoSmontaggio: !ordine || !ordine.costoSmontaggio ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoSmontaggio,
            costoElettricista: !ordine || !ordine.costoElettricista ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElettricista,
            costoIdraulico: !ordine || !ordine.costoIdraulico ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoIdraulico,
            costoElevatore: !ordine || !ordine.costoElevatore ? {
                prezzo: 0,
                conteggiato: false
            } : ordine.costoElevatore
        });
    }, [ordine]);

    const { fattura, misure, totale, acconto, accontiPersonalizzati, saldo, dataPrevista, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, descrizioneGenerale, note, notePagamento, notePreventivo, tipologia, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoIdraulico, costoElevatore } = datiOrdine;

    const [elementiOrdine, setElementiOrdine] = useState([{ _id: '', marca: '', descrizione: '', prezzo: 0, prezzoListino: null, magazzino: 'no', riparazione: null }]);

    useEffect(() => {
        setElementiOrdine([...ordine.elementi]);
    }, [ordine]);

    


    const elementi = elementiOrdine;
    const addElementoPreventivo = () => setElementiOrdine([...elementi, { marca: "", descrizione: "", prezzo: 0, prezzoListino: null, magazzino: 'no', riparazione: false }]);
    const deleteElementoPreventivo = i => {
        let arrayElementiOrdine = elementiOrdine;
        arrayElementiOrdine.splice(i, 1)
        setElementiOrdine([...elementiOrdine]);
        let data = [...elementiOrdine];
        let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        
        var sommaAccontiPersonalizzati = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        let newAcconto = 0;
        if(acconto > 0){
            newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
        }
        let newSaldo = (parseFloat(somma) - newAcconto - sommaAccontiPersonalizzati).toFixed(2);
        setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma), acconto: newAcconto, saldo: newSaldo });
    };

    const createUIelementiOrdine = (elementiOrdine) => {
        return elementiOrdine.map((elementoOrdine, i) => (
            <div class="form-group row">
                <div class="col-lg-2">
                    <textarea value={elementoOrdine.marca} type="text" class="form-control" placeholder="Inserisci fornitore" name="marca" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoOrdine.prezzo >= 0 || elementoOrdine.descrizione.length > 0} />
                </div>
                <div class="col-lg-5">
                    <textarea value={elementoOrdine.descrizione} type="text" class="form-control" placeholder="Inserisci descrizione" name="descrizione" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoOrdine.prezzo >= 0 || elementoOrdine.marca.length > 0} />
                </div>
                <div class="col-lg-2">
                    <input value={elementoOrdine.prezzoListino} type="number" min="0" max="100000" class="form-control prezzo-item" placeholder="Inserisci prezzo" name="prezzoListino" onChange={e => onChangeSetElementoPreventivo(e, i)} />
                </div>
                <div class="col-lg-2">
                    <input value={elementoOrdine.prezzo} type="number" min="0" max="100000" class="form-control prezzo-item" placeholder="Inserisci prezzo" name="prezzo" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoOrdine.descrizione.length > 0 || elementoOrdine.marca.length > 0} />
                </div>
                {elementiOrdine.length == 1 ?
                    null :
                    <div class="col-lg-1">
                        <a class="mt-2 btn btn-danger font-weight-bold btn-pill" onClick={() => deleteElementoPreventivo(i)}>-</a>
                    </div>
                }
            </div>
        ))
    }


    const onChangeSetElementoPreventivo = (e, i) => {
        let data = [...elementiOrdine];
        if (e.target.name === 'prezzo') {
            if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
                data[i] = { ...data[i], prezzo: null };
                setElementiOrdine(data);
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                var sommaAcconti = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                let newAcconto = 0;
                if(acconto > 0){
                    newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
                }
                let newSaldo = (parseFloat(somma) - newAcconto - sommaAcconti).toFixed(2)
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: newAcconto, saldo: newSaldo });
            } else {
                data[i] = { ...data[i], prezzo: parseFloat(e.target.value) };
                setElementiOrdine(data);
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                var sommaAccontiPersonalizzati = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                let newAcconto = 0;
                if(acconto > 0){
                    newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
                }
                let newSaldo = (parseFloat(somma) - newAcconto - sommaAccontiPersonalizzati).toFixed(2)
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma), acconto: newAcconto, saldo: newSaldo });
                setAlertPrezziTotaleControl(false)
            }
        } else {
            data[i] = { ...data[i], [e.target.name]: e.target.value };
            setElementiOrdine(data);
        }
    }

    const [alertPrezziTotaleControl, setAlertPrezziTotaleControl] = useState(false);
    const prezziTotaleControl = (totale) => {
        let data = [...elementiOrdine];
        let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        if (somma !== totale) {
            setAlertPrezziTotaleControl(true)
        }
        else {
            setAlertPrezziTotaleControl(false)
        }
    }



    const onChangeSetFatturaPreventivo = e => setDatiOrdine({ ...datiOrdine, fattura: e.target.value === 'true' ? true : false });
    const onChangeSetMisurePreventivo = e => setDatiOrdine({ ...datiOrdine, misure: e.target.value });
    const onChangeSetTotalePreventivo = e => {
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            setDatiOrdine({ ...datiOrdine, totale: null, acconto: 0, saldo: 0 });
            prezziTotaleControl(parseFloat(e.target.value))
        } else {
            var newAccontiPersonalizzati = accontiPersonalizzati
            newAccontiPersonalizzati.map(acconto => {
                acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
            });
            var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
            let newSaldo = (parseFloat(e.target.value) - (parseFloat(e.target.value) * 0.3) - somma).toFixed(2);
            if (newSaldo < 0) {
                newSaldo = 0;
            }
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(e.target.value), acconto: (parseFloat(e.target.value) * 0.3).toFixed(2), saldo: newSaldo });
            prezziTotaleControl(parseFloat(e.target.value))
        }
    }

    const onChangeSetAccontoPreventivo = e => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati.map(acconto => {
            acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
        });
        var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            setDatiOrdine({ ...datiOrdine, acconto: 0, saldo: totale - somma });
        } else {
            if (totale - somma - parseFloat(e.target.value) < 0) {
                setDatiOrdine({ ...datiOrdine, acconto: 0, saldo: totale - somma });
            } else {
                setDatiOrdine({ ...datiOrdine, acconto: parseFloat(e.target.value), saldo: (totale - parseFloat(e.target.value) - somma).toFixed(2) });
            }
        }
    }


    const onChangeSetNomeAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati[i].nomeAcconto = e.target.value;
        setDatiOrdine({ ...datiOrdine, accontiPersonalizzati: newAccontiPersonalizzati });
    }


    const onChangeSetCifraAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati.map(acconto => {
            acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
        });
        var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            newAccontiPersonalizzati[i].cifraAcconto = null;
            somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
            setDatiOrdine({ ...datiOrdine, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
        } else {
            if (totale - acconto - somma - parseFloat(e.target.value) + newAccontiPersonalizzati[i].cifraAcconto < 0) {
                newAccontiPersonalizzati[i].cifraAcconto = 0;
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiOrdine({ ...datiOrdine, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
            } else {
                newAccontiPersonalizzati[i].cifraAcconto = parseFloat(e.target.value)
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiOrdine({ ...datiOrdine, accontiPersonalizzati: accontiPersonalizzati, saldo: (totale - acconto - somma).toFixed(2) });
            }
        }
    }


    const onChangesetDatiOrdine = e => setDatiOrdine({ ...datiOrdine, [e.target.name]: e.target.value });
    const onChangeSetAscensorePreventivo = e => setDatiOrdine({ ...datiOrdine, ascensorePreventivo: e.target.checked });
    const onChangeSetZTLPreventivo = e => setDatiOrdine({ ...datiOrdine, ztlPreventivo: e.target.checked });
    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });
    const onChangeSetAscensoreCliente = e => setDatiCliente({ ...datiCliente, ascensore: e.target.checked });
    const onChangeSetZTLCliente = e => setDatiCliente({ ...datiCliente, ztl: e.target.checked });
    const onChangeTipologiaPreventivo = e => setDatiOrdine({ ...datiOrdine, tipologia: e.target.value });


    const [copiaIndirizzo, setCopiaIndirizzo] = useState(true);

    const toggleCopiaIndirizzo = (copiaIndirizzo) => {
        setCopiaIndirizzo(!copiaIndirizzo);
        if (!copiaIndirizzo) {
            setDatiOrdine({ ...datiOrdine, indirizzoPreventivo: indirizzo, capPreventivo: cap, cittaPreventivo: citta, pianoPreventivo: piano, scalaPreventivo: scala, ascensorePreventivo: ascensore, ztlPreventivo: ztl, dataPrevista: dataPrevista, misure: misure });
        }
    };

    const onSubmitCreatePreventivo = () => {
        datiOrdine.elementi = elementi;
        datiOrdine.indirizzoConsegna = copiaIndirizzo;
        datiOrdine.cliente = datiCliente;
        modificaOrdine(datiOrdine);
    };

    const onChangeCheckCostoProgetto = (e, attributo) => {
        let data = [...elementiOrdine];
        let newCostoProgetto = costoProgetto;
        let somma = (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
        if (e.target.value === 'true') {
            somma = costoProgetto.prezzo + somma;
            newCostoProgetto.conteggiato = true
        } else {
            newCostoProgetto.conteggiato = false
        }
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        var sommaAccontiPersonalizzati = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        let newAcconto = 0;
        if(acconto > 0){
            newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
        }
        let newSaldo = (parseFloat(somma) - newAcconto - sommaAccontiPersonalizzati).toFixed(2);
        setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma), acconto: newAcconto, saldo: newSaldo }, newCostoProgetto);
    };

    const onChangeCheckCostoServizio = (e, servizio) => {
        let data = [...elementiOrdine];
        let newCostoServizio = servizio;
        let somma = (costoProgetto.conteggiato ? checkIfPrezzoIsValido(costoProgetto.prezzo) : 0) + (costoMisure.conteggiato ? checkIfPrezzoIsValido(costoMisure.prezzo) : 0) + (costoMagazzino.conteggiato ? checkIfPrezzoIsValido(costoMagazzino.prezzo) : 0) + (costoSmontaggio.conteggiato ? checkIfPrezzoIsValido(costoSmontaggio.prezzo) : 0) + (costoElettricista.conteggiato ? checkIfPrezzoIsValido(costoElettricista.prezzo) : 0) + (costoIdraulico.conteggiato ? checkIfPrezzoIsValido(costoIdraulico.prezzo) : 0) + (costoElevatore.conteggiato ? checkIfPrezzoIsValido(costoElevatore.prezzo) : 0);
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        if (e.target.value === 'true') {
            newCostoServizio.conteggiato = true
            somma = somma + checkIfPrezzoIsValido(newCostoServizio.prezzo)
        } else {
            somma = somma - checkIfPrezzoIsValido(newCostoServizio.prezzo)
            newCostoServizio.conteggiato = false
        }
        var sommaAccontiPersonalizzati = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        let newAcconto = 0;
        if(acconto > 0){
            newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
        }
        let newSaldo = (parseFloat(somma) - newAcconto - sommaAccontiPersonalizzati).toFixed(2);
        setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma), acconto: newAcconto, saldo: newSaldo }, newCostoServizio);
    };


    const onChangeCheckCostoMisure = e => {
        let data = [...elementiOrdine];
        let newCostoMisure = costoMisure;
        if (e.target.value === 'true') {
            let somma = costoMisure.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoMisure.conteggiato = true;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoMisure.conteggiato = false;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
        }
    };

    const onChangeCheckCostoMagazzino = e => {
        let data = [...elementiOrdine];
        let newCostoMagazzino = costoMagazzino;
        if (e.target.value === 'true') {
            let somma = costoMagazzino.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoMagazzino.conteggiato = true
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoMagazzino.conteggiato = false
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino });
        }
    };

    const onChangeCheckCostoSmontaggio = e => {
        let newCostoSmontaggio = costoSmontaggio;
        let data = [...elementiOrdine];
        if (e.target.value === 'true') {
            let somma = costoSmontaggio.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoSmontaggio.conteggiato = true
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoSmontaggio.conteggiato = false
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
        }
    };

    const onChangeCheckCostoElettricista = e => {
        let data = [...elementiOrdine];
        let newCostoElettricista = costoElettricista;
        if (e.target.value === 'true') {
            let somma = costoElettricista.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoElettricista.conteggiato = true
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoElettricista.conteggiato = false;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
        }
    };

    const onChangeCheckCostoIdraulico = e => {
        let data = [...elementiOrdine];
        let newCostoIdraulico = costoIdraulico;
        if (e.target.value === 'true') {
            let somma = costoIdraulico.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoIdraulico.conteggiato = true;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoIdraulico.conteggiato = false;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
        }
    };

    const onChangeCheckCostoElevatore = e => {
        let data = [...elementiOrdine];
        let newCostoElevatore = costoElevatore;
        if (e.target.value === 'true') {
            let somma = costoElevatore.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoElevatore.conteggiato = true;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
        } else {
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
            data.map((elemento) => (
                somma = somma + elemento.prezzo
            ));
            newCostoElevatore.conteggiato = false;
            setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
        }
    };

    const onChangeSetCostoExtra = (e, i) => {
        let data = [...elementiOrdine];
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            if (e.target.name === 'costoProgetto') {
                let somma = (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoProgetto = costoProgetto;
                newCostoProgetto.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoProgetto });
            } else if (e.target.name === 'costoMisure') {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMisure = costoMisure;
                newCostoMisure.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
            } else if (e.target.name === 'costoMagazzino') {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMagazzino = costoMagazzino;
                newCostoMagazzino.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino });
            } else if (e.target.name === 'costoSmontaggio') {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoSmontaggio = costoSmontaggio;
                newCostoSmontaggio.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
            } else if (e.target.name === 'costoElettricista') {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElettricista = costoElettricista;
                newCostoElettricista.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
            } else if (e.target.name === 'costoIdraulico') {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoIdraulico = costoIdraulico;
                newCostoIdraulico.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
            } else {
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElevatore = costoElevatore;
                newCostoElevatore.prezzo = null;
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
            }
        } else {
            if (e.target.name === 'costoProgetto') {
                let somma = (costoProgetto.conteggiato ? parseFloat(e.target.value) : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoProgetto = costoProgetto;
                newCostoProgetto.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoProgetto });
            } else if (e.target.name === 'costoMisure') {
                let somma = (costoMisure.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMisure = costoMisure;
                newCostoMisure.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
            } else if (e.target.name === 'costoMagazzino') {
                let somma = (costoMagazzino.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMagazzino = costoMagazzino;
                newCostoMagazzino.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino });
            } else if (e.target.name === 'costoSmontaggio') {
                let somma = (costoSmontaggio.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoSmontaggio = costoSmontaggio;
                newCostoSmontaggio.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
            } else if (e.target.name === 'costoElettricista') {
                let somma = (costoElettricista.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElettricista = costoElettricista;
                newCostoElettricista.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
            } else if (e.target.name === 'costoIdraulico') {
                let somma = (costoIdraulico.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoIdraulico = costoIdraulico;
                newCostoIdraulico.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
            } else {
                let somma = (costoElevatore.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElevatore = costoElevatore;
                newCostoElevatore.prezzo = parseFloat(e.target.value);
                setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
            }
        }
    }

    const checkIfPrezzoIsValido = (prezzo) => {
        if(isNaN(parseFloat(prezzo)) || !prezzo){
            return null
        }else{
            return parseFloat(prezzo)
        }
    }

    const onChangeSetCostoServizio = (e, servizio) => {
        let data = [...elementiOrdine];
        let somma = (costoProgetto.conteggiato ? checkIfPrezzoIsValido(costoProgetto.prezzo) : 0) + (costoMisure.conteggiato ? checkIfPrezzoIsValido(costoMisure.prezzo) : 0) + (costoMagazzino.conteggiato ? checkIfPrezzoIsValido(costoMagazzino.prezzo) : 0) + (costoSmontaggio.conteggiato ? checkIfPrezzoIsValido(costoSmontaggio.prezzo) : 0) + (costoElettricista.conteggiato ? checkIfPrezzoIsValido(costoElettricista.prezzo) : 0) + (costoIdraulico.conteggiato ? checkIfPrezzoIsValido(costoIdraulico.prezzo) : 0) + (costoElevatore.conteggiato ? checkIfPrezzoIsValido(costoElevatore.prezzo) : 0);
        if(servizio.conteggiato){
            somma = somma - checkIfPrezzoIsValido(servizio.prezzo) + checkIfPrezzoIsValido(e.target.value)
        }
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        let newCostoServizio = servizio;
        newCostoServizio.prezzo = checkIfPrezzoIsValido(e.target.value);
        var sommaAccontiPersonalizzati = accontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        let newAcconto = 0;
        if(acconto > 0){
            newAcconto = (parseFloat(somma) * 0.3).toFixed(2)
        }
        let newSaldo = (parseFloat(somma) - newAcconto - sommaAccontiPersonalizzati).toFixed(2);
        setDatiOrdine({ ...datiOrdine, totale: parseFloat(somma), acconto: newAcconto, saldo: newSaldo }, newCostoServizio);
    }

    return (
        <div class="d-flex flex-column-fluid">
            <div class=" container ">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card card-custom example example-compact">
                            <div class="card-header">
                                <h3 class="card-title">Modifica Preventivo</h3>
                            </div>
                            <div className="form" >
                                <div className="card-body">
                                    <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                                    <div className="">
                                        <div className="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Fattura:</label>
                                                    <div class="radio-inline">
                                                        <label class="radio">
                                                            <input type="radio" value={true} checked={fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                                            <span></span>   Si

                                                        </label>
                                                        <label class="radio">
                                                            <input type="radio" value={false} checked={!fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                                            <span></span>    No

                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
                                                    <label>Cognome: <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cognome" required disabled/>
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Nome: <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci nome" required disabled/>
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Email 1:</label>
                                                    <input type="email" class="form-control" name="email_1" value={email_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Email 2:</label>
                                                    <input type="email" class="form-control" name="email_2" value={email_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
                                                    <label>Telefono 1: <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="telefono_1" value={telefono_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" required />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Telefono 2:</label>
                                                    <input type="text" class="form-control" name="telefono_2" value={telefono_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Telefono 3:</label>
                                                    <input type="text" class="form-control" name="telefono_3" value={telefono_3} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Telefono 4:</label>
                                                    <input type="text" class="form-control" name="telefono_4" value={telefono_4} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>P.Iva/C.F.:</label>
                                                    <input type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci P.Iva/C.F." />
                                                </div>
                                                <div class="col-lg-6">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Indirizzo:</label>
                                                    <input type="text" class="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci indirizzo" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>CAP:</label>
                                                    <input type="text" class="form-control" name="cap" value={cap} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cap" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Città:</label>
                                                    <input type="text" class="form-control" name="citta" value={citta} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci città" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
                                                    <label>Piano:</label>
                                                    <input type="text" class="form-control" name="piano" value={piano} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci piano" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Scala:</label>
                                                    <input type="text" class="form-control" name="scala" value={scala} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci scala" />
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Accessibilità:</label>
                                                    <div class="checkbox-inline">
                                                        <label class="checkbox">
                                                            <input type="checkbox" checked={ascensore} onChange={e => onChangeSetAscensoreCliente(e)} />
                                                            <span></span>
                                                            Ascensore

                                                        </label>
                                                        <label class="checkbox">
                                                            <input type="checkbox" checked={ztl} onChange={e => onChangeSetZTLCliente(e)} />
                                                            <span></span>
                                                            ZTL

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Indirizzo di consegna:</h3>
                                        <div class="checkbox-inline mb-5">
                                            <label class="checkbox">
                                                <input type="checkbox" checked={copiaIndirizzo} onClick={() => toggleCopiaIndirizzo(copiaIndirizzo)} />
                                                <span></span> Copia l'indirizzo del cliente come indirizzo di consegna
                                            </label><br />
                                        </div>
                                        <div style={{ display: copiaIndirizzo ? 'none' : null }} className="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Indirizzo:</label>
                                                    <input disabled={copiaIndirizzo} type="text" class="form-control" name="indirizzoPreventivo" value={indirizzoPreventivo} onChange={e => onChangesetDatiOrdine(e)} placeholder="Inserisci indirizzo" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>CAP:</label>
                                                    <input disabled={copiaIndirizzo} type="text" class="form-control" name="capPreventivo" value={capPreventivo} onChange={e => onChangesetDatiOrdine(e)} placeholder="Inserisci cap" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Città:</label>
                                                    <input disabled={copiaIndirizzo} type="text" class="form-control" name="cittaPreventivo" value={cittaPreventivo} onChange={e => onChangesetDatiOrdine(e)} placeholder="Inserisci città" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
                                                    <label>Piano:</label>
                                                    <input disabled={copiaIndirizzo} type="text" class="form-control" name="pianoPreventivo" value={pianoPreventivo} onChange={e => onChangesetDatiOrdine(e)} placeholder="Inserisci piano" />
                                                </div>
                                                <div class="col-lg-3">
                                                    <label>Scala:</label>
                                                    <input disabled={copiaIndirizzo} type="text" class="form-control" name="scalaPreventivo" value={scalaPreventivo} onChange={e => onChangesetDatiOrdine(e)} placeholder="Inserisci scala" />
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Accessibilità:</label>
                                                    <div class="checkbox-inline">
                                                        <label class="checkbox">
                                                            <input disabled={copiaIndirizzo} type="checkbox" checked={ascensorePreventivo} onChange={e => onChangeSetAscensorePreventivo(e)} />
                                                            <span></span>
                                                            Ascensore

                                                        </label>
                                                        <label class="checkbox">
                                                            <input disabled={copiaIndirizzo} type="checkbox" checked={ztlPreventivo} onChange={e => onChangeSetZTLPreventivo(e)} />
                                                            <span></span>
                                                            ZTL

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-lg-3"></div>
                                            </div>
                                        </div>
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati preventivo:</h3>
                                        <div className="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Misure:</label>
                                                    <div class="radio-inline">
                                                        <label class="radio">
                                                            <input type="radio" value="venditore" checked={misure === "venditore"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                                            <span></span>
                                                            A carico del venditore
                                                        </label>
                                                        <label class="radio">
                                                            <input type="radio" value="cliente" checked={misure === "cliente"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                                            <span></span>
                                                            Comunicati dal cliente
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-10">
                                                    <label>Descrizione generale:</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label>Tipologia preventivo:</label>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-10">
                                                    <textarea type="text" class="form-control" placeholder="Inserisci descrizione generale" name="descrizioneGenerale" value={descrizioneGenerale} onChange={e => onChangesetDatiOrdine(e)} />
                                                </div>
                                                <div class="col-lg-2">
                                                    <select class="form-control" name="tipologia" onChange={e => onChangeTipologiaPreventivo(e)} >
                                                        <option selected={tipologia === 'cucina'} value={'cucina'}>Cucina</option>
                                                        <option selected={tipologia === 'soggiorno'} value={'soggiorno'}>Soggiorno</option>
                                                        <option selected={tipologia === 'cameradaletto'} value={'cameradaletto'}>Camera da letto</option>
                                                        <option selected={tipologia === 'interoarredamento'} value={'interoarredamento'}>Intero arredamento</option>
                                                        <option selected={tipologia === 'complementi'} value={'complementi'}>Complementi</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-2">
                                                    <label>Fornitore</label>
                                                </div>
                                                <div class="col-lg-5">
                                                    <label>Descrizione:</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label>Prezzo Listino</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label>Prezzo Scontato</label>
                                                </div>
                                                <div class="col-lg-1">
                                                    <span></span>
                                                </div>
                                            </div>
                                            {createUIelementiOrdine(elementiOrdine)}
                                            <a class="btn btn-success font-weight-bold btn-pill" onClick={() => addElementoPreventivo()}>+</a>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Note Preventivo:</label>
                                                    <textarea type="text" class="form-control" placeholder="Inserisci note preventivo" name="notePreventivo" value={notePreventivo} onChange={e => onChangesetDatiOrdine(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Consulenza, progetto/i (di proprietà AR-CA arredamenti fino a stipula del contratto), preventivo/i:</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoProgetto.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoProgetto)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoProgetto.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoProgetto)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoProgetto" value={costoProgetto.prezzo} onChange={e => onChangeSetCostoServizio(e, costoProgetto)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Rilevo misure, disegni per architettonico, disegno per impianti, verifica esecuzione lavori:</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoMisure.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoMisure)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoMisure.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoMisure)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoMisure" value={costoMisure.prezzo} onChange={e => onChangeSetCostoServizio(e, costoMisure)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Servizi di magazzinaggio, carico, trasporto, scarico al piano, sballaggio, montaggio, smaltimento imballi:</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoMagazzino.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoMagazzino)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoMagazzino.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoMagazzino)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoMagazzino" value={costoMagazzino.prezzo} onChange={e => onChangeSetCostoServizio(e, costoMagazzino)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Eventuali servizi per smontaggio, smaltimento, riposizionamento, modifiche, trasloco, montaggio mobili/elettrodomestici del cliente (30,00€ per operaio):</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoSmontaggio.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoSmontaggio)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoSmontaggio.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoSmontaggio)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoSmontaggio" value={costoSmontaggio.prezzo} onChange={e => onChangeSetCostoServizio(e, costoSmontaggio)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Allacciamenti elettrici (montaggio lampade):</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoElettricista.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoElettricista)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoElettricista.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoElettricista)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoElettricista" value={costoElettricista.prezzo} onChange={e => onChangeSetCostoServizio(e, costoElettricista)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Allacciamenti idraulici (acqua e gas):</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoIdraulico.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoIdraulico)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoIdraulico.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoIdraulico)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoIdraulico" value={costoIdraulico.prezzo} onChange={e => onChangeSetCostoServizio(e, costoIdraulico)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="mb-0">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                        <label>Eventuale servizio per occupazione suolo pubblico ed elevatore o facchinaggio:</label>
                                                        <div class="radio-inline">
                                                            <label class="radio">
                                                                <input type="radio" value={true} checked={costoElevatore.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoElevatore)} />
                                                                <span></span>
                                                                Si
                                                            </label>
                                                            <label class="radio">
                                                                <input type="radio" value={false} checked={!costoElevatore.conteggiato} onChange={e => onChangeCheckCostoServizio(e, costoElevatore)} />
                                                                <span></span>
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <input type="number" step="0.001" class="form-control" name="costoElevatore" value={costoElevatore.prezzo} onChange={e => onChangeSetCostoServizio(e, costoElevatore)} placeholder="Inserisci prezzo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-15">
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Totale preventivo </label> {alertPrezziTotaleControl ? <span class="label label-xl label-danger label-pill label-inline mr-2">Totale non corretto</span> : null}
                                                        <input type="number" step="0.001" class="form-control" name="totale" value={totale} onChange={e => onChangeSetTotalePreventivo(e)} placeholder="Inserisci totale" required />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Caparra confirmatoria</label>
                                                        {acconto < totale * 0.3 ? <span class="ml-1 label label-xl label-danger label-pill label-inline mr-2">minore del 30%</span> : null}
                                                        <input type="text" class="form-control" name="acconto" value={acconto} placeholder="Inserisci caparra confirmatoria" onChange={e => onChangeSetAccontoPreventivo(e)} required />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-6">
                                                        <label>Note Pagamento:</label>
                                                        <textarea type="text" class="form-control" placeholder="Inserisci note pagamento" name="notePagamento" value={notePagamento} onChange={e => onChangesetDatiOrdine(e)} />
                                                    </div>
                                                    <div class="col-lg-2">
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Opzione pers.</label>
                                                        <input type="text" class="form-control" name="accontoPersonalizzato1" value={accontiPersonalizzati[0].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 0)} />
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="number" class="form-control mt-8" name="accontoPersonalizzato1.cifraAcconto" value={accontiPersonalizzati[0].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 0)} />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Opzione pers.</label>
                                                        <input type="text" class="form-control" name="accontoPersonalizzato2.nomeAcconto" value={accontiPersonalizzati[1].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 1)} />
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="number" class="form-control mt-8" name="accontoPersonalizzato2.cifraAcconto" value={accontiPersonalizzati[1].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 1)} />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-8">
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Saldo</label>
                                                        <input type="text" class="form-control" name="saldo" value={saldo} placeholder="Inserisci saldo" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-lg-6 text-left">
                                                    <button onClick={() => setOpenModificaOrdine(false)} className="btn btn-secondary">Esci</button>
                                                </div>
                                                <div className="col-lg-6 text-right">
                                                    <button onClick={() => onSubmitCreatePreventivo()} className="btn btn-success mr-2 salva-btn">Salva</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

ModificaOrdineConfermato.protoTypes = {
    modificaOrdine: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { modificaOrdine })(ModificaOrdineConfermato);

